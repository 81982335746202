import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import useDeleteTaskFile from './useDeleteTaskFile';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} >{props.children}</Slide>;
});

type DeleteDialogProps = {
    open: boolean;
    onClose: () => void;
    fileId: string;
    taskId: string;
};

export default function DeleteFileDialog(
    { open, onClose, fileId, taskId }: DeleteDialogProps): JSX.Element {
    const { mutate: deleteFileFromTask } = useDeleteTaskFile(fileId, taskId, onClose);

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
            >
                <DialogTitle id="delete--dialog-title"
                    sx={{ textAlign: 'center' }}>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete--dialog-description" sx={{ textAlign: 'center' }}>
                        This action is irreversible.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={() => deleteFileFromTask()} color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
