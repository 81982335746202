import { useMutation } from 'react-query';
import { API } from '../../../utils/ApiClient';
import Task from '../../../models/Task';

function useCalculatePrice(taskId?: string) {
    return useMutation< {
    data: {
        taskPrice: number
        error ? : unknown
    }
}, unknown, Task, unknown>({
    mutationFn(task) {
        return API.post(`/tasks/${taskId}/calculate`, task);
    },
});
}

export default useCalculatePrice;
