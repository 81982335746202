import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/global.scss';
import { HelmetProvider } from 'react-helmet-async';

import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

ReactDOM.render(
    <React.StrictMode>
        <HelmetProvider>
            <SettingsProvider>
                <CollapseDrawerProvider>
                    <App />
                </CollapseDrawerProvider>
            </SettingsProvider>
        </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
