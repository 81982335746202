import EntityBase from './EntityBase';
import Priority from './Priority';
import TranslationOffer from './TranslationOffer';

export default class Price extends EntityBase {
    public priorityId?: string;
    public priority?: Priority;
    public translationOfferId?: string;
    public translationOffer?: TranslationOffer;
    public swornLowPrice?: number;
    public swornHighPrice?: number;
    public freeLowPrice?: number;
    public freeHighPrice?: number;
}
