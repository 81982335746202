import { styled } from '@mui/material/styles';
import { Box, Card, Container, Table, TableRow, TableBody,
    TableCell, Typography, TableContainer,
} from '@mui/material';
import Scrollbar from '../../../components/Scrollbar';
import Page from '../../../components/Page';
import ReportToolbar from './ReportToolbar';
import { useParams } from 'react-router-dom';
import useReportById from './useReportById';
import NoItemsToShow from '../../../components/ConnectedTable/NoItemsToShow';
import getShortLanguageName from '../../../utils/getShortLanguageName';
import ReportDetailsTableHead from './ReportDetailsTableHead';
import { useQuery } from 'react-query';
import Language from '../../../models/Language';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& td': {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
    },
}));

export default function ReportDetails(): JSX.Element {
    const { id: reportId } = useParams();
    const { data: report } = useReportById(reportId);
    const { data: languages } = useQuery<Language[]>('languages');

    const totalPrice = report?.tasks?.reduce((sum, task) => {
        if (task.translatorPrice) return sum += +task.translatorPrice;
        return sum;
    }, 0);

    const priceHandler = (isCustom?: boolean, price?: number) => {
        if (isCustom) {
            return 'Custom';
        } else if (price) {
            return price + ' €';
        }
        return 'n/a';
    };

    const languageMatcher = (languageId?: string) => {
        const language = languages?.find((lang) => lang.id === languageId);
        return getShortLanguageName(language?.name) ?? 'n/a';
    };

    return (
        <Page title="Reports | WeTranslate">
            <Container maxWidth={'lg'}>
                <ReportToolbar report={report} reportId={reportId} />
                <Scrollbar>
                    <Card sx={{ pt: 5, px: 5, py: 2 }}>
                        <TableContainer sx={{ minWidth: 960 }}>
                            <Table>
                                <ReportDetailsTableHead />

                                <TableBody>
                                    {report?.tasks ? (report.tasks.map((task, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{
                                                borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                                            }}
                                        >
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell align="left">
                                                <Box sx={{ maxWidth: 560 }}>
                                                    <Typography variant="subtitle2">{task.taskId}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell align="left">{languageMatcher(task.sourceLanguageId)}</TableCell>
                                            <TableCell align="left">{languageMatcher(task.targetLanguageId)}</TableCell>
                                            <TableCell align="left">{task.lowPages}</TableCell>
                                            <TableCell align="left">{task.highPages}</TableCell>
                                            <TableCell align="right">{priceHandler(task.customTranslatorPrice, task.translatorLowPrice)}</TableCell>
                                            <TableCell align="right">{priceHandler(task.customTranslatorPrice, task.translatorHighPrice)}</TableCell>
                                            <TableCell align="right">{task.translatorPrice} €</TableCell>
                                        </TableRow>))) : <NoItemsToShow numberOfColumns={6} numberOfRows={4} />}

                                    <StyledTableRow>
                                        <TableCell colSpan={7} />
                                        <TableCell align="right">
                                            <Typography variant="h6">Total</Typography>
                                        </TableCell>
                                        <TableCell align="right" width={140}>
                                            <Typography variant="h6">{totalPrice + ' €'}</Typography>
                                        </TableCell>
                                    </StyledTableRow>
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Card>
                </Scrollbar>
            </Container>
        </Page>
    );
}
