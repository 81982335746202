import { Card, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import SelectField from '../../../components/Form/fields/SelectField';
import useCustomerOptions from './useCustomerOptions';

const CustomerSelector: React.FC = () => {
    const { data: options } = useCustomerOptions();

    return useMemo(() => {
        return (
            <Card sx={{ p: 2 }}>
                <Stack spacing={2}>
                    <Typography variant='subtitle2' gutterBottom>
                        Customer
                    </Typography>
                    <SelectField options={options ?? []} name={'customerId'} label={'Set Customer'} />
                </Stack>
            </Card>
        );
    }, [options]);
};

export default CustomerSelector;
