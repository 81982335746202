import { Box, Button, Card, Chip, Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { PriceForm, TranslationOfferWithPrices } from './Prices';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import SelectRemoteOptions from '../../Form/fields/SelectRemoteOptions';
import React from 'react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
import useDeleteTranslationOffer from './useDeleteTranslationOffer';
import plusFill from '@iconify/icons-eva/plus-fill';
import Priority from '../../../models/Priority';
import { useQuery } from 'react-query';
import Price from '../../../models/Price';
import TextField from '../../Form/fields/TextField';
import useMe from '../../../hooks/useMe';

const PriceEditors: React.FC = () => {
    const { mutate: deleteOffer } = useDeleteTranslationOffer();
    const { data: me } = useMe();
    const { id } = useParams();
    const { setFieldValue, values } = useFormikContext<PriceForm>();
    const { data: priorities } = useQuery<Priority[]>(['priorities', '?', 'customerId=', id]);

    const onAddPrice = () => {
        const offer: TranslationOfferWithPrices = {
            id: uuidv4(),
            customerId: id,
            prices: [],
            sourceLanguageId: '',
            targetLanguageId: '',
            formType: 'new',
        };

        if (priorities) {
            for (let i = 0; i < priorities.length; i++) {
                const newPrice = new Price();
                newPrice.id = uuidv4();
                newPrice.priority = priorities[i];
                newPrice.priorityId = priorities[i].id;
                newPrice.translationOfferId = offer.id;
                offer.prices.push(newPrice);
            }
        }

        setFieldValue('offers', [...values.offers, offer]);
    };


    function removeOfferByIndex(offerIndex: number) {
        const offerToDelete = values.offers[offerIndex];
        if (offerToDelete.formType === 'new') {
            setFieldValue('offers', values.offers.filter((offer, index) => index !== offerIndex));
        } else {
            deleteOffer({ id: offerToDelete.id });
        }
    }


    return <>
        {values.offers.map((offer, index) => (
            <Card sx={{ p: 2, mb: 2 }} key={offer.id}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
                    <Typography variant={'h6'}>{index + 1}. Price</Typography>
                    <IconButton onClick={() => removeOfferByIndex(index)} disabled={!me?.isAdmin}>
                        <Icon icon={trash2Outline}/>
                    </IconButton>
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <SelectRemoteOptions label={'From'} name={`offers[${index}].sourceLanguageId`} queryKey={'languages'} disabled={!me?.isAdmin}/>
                    <SelectRemoteOptions label={'To'} name={`offers[${index}].targetLanguageId`} queryKey={'languages'} disabled={!me?.isAdmin}/>
                </Stack>
                {offer.prices.map((p, i) => (
                    <Stack key={p.id} direction='row' gap={2} sx={{ mt: 2 }}>
                        <Box sx={{ width: '150px' }}>
                            <Chip label={priorities && priorities.find((prio) => prio.id === p.priorityId)?.name} color="primary" />
                        </Box>
                        <TextField label={'Sworn Low'} name={`offers[${index}].prices[${i}].swornLowPrice`} disabled={!me?.isAdmin}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        €
                                    </InputAdornment>
                                ),
                            }}/>
                        <TextField label={'Sworn High'} name={`offers[${index}].prices[${i}].swornHighPrice`} disabled={!me?.isAdmin}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        €
                                    </InputAdornment>
                                ),
                            }}/>
                        <TextField label={'Free Low'} name={`offers[${index}].prices[${i}].freeLowPrice`} disabled={!me?.isAdmin}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        €
                                    </InputAdornment>
                                ),
                            }}/>
                        <TextField label={'Free High'} name={`offers[${index}].prices[${i}].freeHighPrice`} disabled={!me?.isAdmin}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        €
                                    </InputAdornment>
                                ),
                            }} />
                    </Stack>
                ))}
            </Card>
        ))}
        {me?.isAdmin && (
            <Stack direction='column' gap={2} alignItems="center">
                <Button color="inherit"
                    startIcon={<Icon icon={plusFill} width={20} height={20}/>}
                    onClick={onAddPrice}
                >
                    Add Price
                </Button>
                <Button type={'submit'} variant={'contained'}>Save</Button>
            </Stack>
        )}
    </>;
};

export default PriceEditors;
