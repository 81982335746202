import { Card, CardContent, CardHeader, Container, Stack } from '@mui/material';
import { useQuery } from 'react-query';
import Language from '../../../../models/Language';
import Form from '../../../Form';
import LanguageEditor from './LanguageEditor';
import useSaveLanguages from './useSaveLanguages';


export type LanguageForm = {
  languages: Language[]
}

export default function Languages(): JSX.Element {
    const { data: languages } = useQuery<Language[]>('languages');
    const { mutate } = useSaveLanguages();

    const onSubmit = (form: LanguageForm) => {
        mutate({ languages: form.languages });
    };


    return (
        <Container>
            <Card sx={{ p: 2 }}>
                <CardHeader title="Specify the languages that can be selected in the system" />
                <CardContent>
                    <Stack spacing={3}>
                        <Form<LanguageForm> onSubmit={onSubmit} initialValues={{ languages: languages ?? [] }}>
                            <LanguageEditor />
                        </Form>
                    </Stack>
                </CardContent>
            </Card>
        </Container>
    );
}

