import React from 'react';
import { Chip, Stack, Typography, useTheme } from '@mui/material';
import Label from '../../../../components/Label';
import { LoadingButton } from '@mui/lab';
import calculationErrorMessage from '../calculationErrorMessage';
import { useField, useFormikContext } from 'formik';
import Task from '../../../../models/Task';
import useCalculatePrice from '../useCalculatePrice';

const PricingCalculator: React.FC = () => {
    const { values: task } = useFormikContext<Task>();
    const { data, mutate: calculatePrice, isSuccess } = useCalculatePrice(task.id);
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const fieldName: keyof Task = 'price';
    const [field] = useField(fieldName);

    const taskPrice = data?.data?.taskPrice;
    const isError = data?.data?.error;

    const onCalculatePrice = () => {
        calculatePrice(task);
    };

    return <>

        <Stack direction={'row'} gap={2}>
            <Stack gap={1}>
                <Chip label={`${field.value} €`} color="info" />
                {isSuccess && <Label variant={isLight ? 'ghost' : 'filled'}>Saved Price</Label>}
            </Stack>
            {taskPrice !== undefined && <Stack gap={1}>
                <Chip label={`${taskPrice} €`} color="info"/>
                <Label variant={isLight ? 'ghost' : 'filled'}>New Calculation</Label>
            </Stack>}
        </Stack>
        <LoadingButton
            onClick={onCalculatePrice}
            fullWidth
            disabled={!task.include}
            variant="contained"
            size="small"
            color="secondary">
            Calculate New Price
        </LoadingButton>

        {isError && <Typography variant={'subtitle2'} color={'error'}>
            {calculationErrorMessage}
        </Typography>}
    </>;
};

export default PricingCalculator;

