import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../utils/ApiClient';
import { useSnackbar } from 'notistack';

function useDeleteHoliday() {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    return useMutation<unknown, unknown, { id?: string }>({
        mutationFn: ({ id }) => API.delete(`/holidays/${id}`),
        onSuccess() {
            enqueueSnackbar('Deletion successful');
            queryClient.invalidateQueries('holidays');
        },
    });
}

export default useDeleteHoliday;
