import { Card, Grid, Typography } from '@mui/material';
import TextField from '../../../Form/fields/TextField';
import { useFormikContext } from 'formik';
import ProfileEditorForm from '../ProfileEditorForm';

const PersonalData: React.FC = () => {
    const { values, errors } = useFormikContext<ProfileEditorForm>();
    const isAgentOrUser = values.role === 'agent' || values.role === 'user';

    return <Grid item xs={isAgentOrUser ? 12 : 8} >
        <Card sx={{ p: 2 }}>
            <Typography mb={2} variant={'h5'}>Personal data</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField name={'firstname'} label={'First name'} />
                </Grid>
                <Grid item xs={6}>
                    <TextField name={'lastname'} label={'Last name'} />
                </Grid>
                <Grid item xs={6}>
                    <TextField name={'phone'} label={'Phone number'} />
                </Grid>
                <Grid item xs={6}>
                    <TextField name={'email'} label={'E-mail'} />
                </Grid>
            </Grid>
        </Card>
    </Grid>
    ;
};

export default PersonalData;
