import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import User from '../../../models/User';
import { API } from '../../../utils/ApiClient';
import Form from '../../Form';
import TextField from '../../Form/fields/TextField';
import SelectField from '../../Form/fields/SelectField';
import { Button, Stack } from '@mui/material';


const ROLE_OPTIONS = [
    { id: 'user', value: 'user', name: 'User' },
    { id: 'agent', value: 'agent', name: 'Agent' },
];

type FormProps = {
    id?: string;
    onClose: () => void;
};


export default function EmployeeForm({ onClose, id }: FormProps): JSX.Element {
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const { mutate: addUser } = useMutation({
        mutationFn: (user: User) => API.post('users', user),
        onSuccess() {
            enqueueSnackbar('User added', { variant: 'success' });
            queryClient.invalidateQueries('users');
            onClose?.();
        },
    });

    const UserSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email required'),
        firstname: Yup.string().required('First name required'),
        lastname: Yup.string().required('Last name required'),
    });

    const onSubmit = (user: User) => {
        user.customerId = id;
        addUser(user);
    };

    return (
        <Form<User>
            validationSchema={UserSchema}
            onSubmit={onSubmit}
            initialValues={new User()}>
            <Stack spacing={2} alignItems='center' sx={{ p: 2 }}>
                <TextField name={'firstname'} label={'First Name'} />
                <TextField name={'lastname'} label={'Last Name'} />
                <TextField name={'email'} label={'Email address'} />
                <SelectField sx={{ width: '100%' }} options={ROLE_OPTIONS} name={'role'} label={'Role'} />
                <Stack direction='row' gap={1}>
                    <LoadingButton type="submit" variant="contained">
                        Save
                    </LoadingButton>
                    <Button type="button" variant="contained" color="inherit" onClick={onClose}>
                        Cancel
                    </Button>
                </Stack>
            </Stack>
        </Form>
    );
}
