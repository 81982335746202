import { IconButton, InputAdornment, Stack } from '@mui/material';
import TextField from '../../Form/fields/TextField';
import { Icon } from '@iconify/react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import React from 'react';
import { useFormikContext } from 'formik';
import { PriceForm, TranslationOfferWithPrices } from './Prices';
import useDeletePrice from './useDeletePrice';
import { useParams } from 'react-router-dom';
import useUserById from '../../../hooks/useUserById';

type Props = {
  offer: TranslationOfferWithPrices;
  index: number
}

const PriceEditor: React.FC<Props> = ({ offer, index }) => {
    const { mutate: deletePrice } = useDeletePrice();
    const { id } = useParams();
    const { data: user } = useUserById(id);
    const { setFieldValue, values } = useFormikContext<PriceForm>();


    function onRemovePrice(offerIndex: number, priceIndex: number) {
        const priceToRemove = values.offers[offerIndex].translatorPrices[priceIndex];
        if (priceToRemove.formType === 'new') {
            setFieldValue('offers', [
                ...values.offers.slice(0, offerIndex),
                {
                    ...values.offers[offerIndex],
                    translatorPrices: values.offers[offerIndex].translatorPrices.filter((_, i) => i !== priceIndex),
                },
                ...values.offers.slice(offerIndex + 1),
            ]);
        } else {
            deletePrice({ id: priceToRemove.id });
        }
    }
    const isShowSworn = user?.style === 'sworn' || user?.style === 'both';
    const isShowFree = user?.style === 'free' || user?.style === 'both';

    return <Stack>
        {offer.translatorPrices?.map((price, priceIndex) => (
            <Stack key={price.id} gap={2} direction={'row'} mt={2}>
                <TextField
                    type={'number'}
                    label={'Hours'} name={`offers[${index}].translatorPrices[${priceIndex}].hours`}/>
                {isShowSworn && <TextField
                    type={'number'}
                    InputProps={{
                        endAdornment:
              <InputAdornment position="end">
                €
              </InputAdornment>,
                    }}
                    label={'sworn low'} name={`offers[${index}].translatorPrices[${priceIndex}].swornLowPrice`}/>}
                {isShowSworn && <TextField
                    type={'number'}
                    InputProps={{
                        endAdornment:
              <InputAdornment position="end">
                €
              </InputAdornment>,
                    }}
                    label={'sworn high'} name={`offers[${index}].translatorPrices[${priceIndex}].swornHighPrice`}/>}
                {isShowFree && <TextField
                    type={'number'}
                    InputProps={{
                        endAdornment:
              <InputAdornment position="end">
                €
              </InputAdornment>,
                    }}
                    label={'free low'} name={`offers[${index}].translatorPrices[${priceIndex}].freeLowPrice`}/>}
                {isShowFree && <TextField
                    type={'number'}
                    InputProps={{
                        endAdornment:
              <InputAdornment position="end">
                €
              </InputAdornment>,
                    }}
                    label={'free high'} name={`offers[${index}].translatorPrices[${priceIndex}].freeHighPrice`}/>}
                <IconButton onClick={() => onRemovePrice(index, priceIndex)}>
                    <Icon icon={trash2Outline} width={20} height={20}/>
                </IconButton>
            </Stack>
        ))}
    </Stack>;
};

export default PriceEditor;
