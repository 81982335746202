import { Card, Stack, Typography, Divider } from '@mui/material';
import { useQuery } from 'react-query';

type InfoProps = {
    customerId?: string;
};

export default function Info({ customerId }: InfoProps): JSX.Element {
    const { data: info } = useQuery<{ employees: number, tasks: number }>(`/dashboard/customer/${customerId}`);

    return (
        <Card sx={{ py: 3 }}>
            <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
                <Stack width={1} textAlign="center">
                    <Typography variant="h4">{info?.employees}</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Employees
                    </Typography>
                </Stack>

                <Stack width={1} textAlign="center">
                    <Typography variant="h4">{info?.tasks}</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Tasks
                    </Typography>
                </Stack>
            </Stack>
        </Card>
    );
}
