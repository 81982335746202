import { useEffect } from 'react';
import { Socket } from 'socket.io-client';
import { useQueryClient } from 'react-query';
import Task from '../models/Task';

function useTaskParseUpdate(socket: Socket | null) {
    const queryClient = useQueryClient();

    useEffect(() => {
        socket?.on(
            'TRANSLATION_FILE_PARSE_STATUS_UPDATE',
            (message: {originalFileParseStatus: any, taskId: string, originalFileCharacterCount: number}) => {
                const task: Task | undefined = queryClient.getQueryData(['tasks/', message.taskId]);
                queryClient.setQueryData(['tasks/', message.taskId], ({
                    ...task,
                    originalFileParseStatus: message.originalFileParseStatus,
                    originalFileCharacterCount:
                        message.originalFileParseStatus === 'parsed' ? message.originalFileCharacterCount : null,
                }));
            });

        return () => {
            socket?.off('TRANSLATION_FILE_PARSE_STATUS_UPDATE');
        };
    }, [queryClient, socket]);
}

export default useTaskParseUpdate;
