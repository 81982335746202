/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { TableContainer, Table, TableHead, Typography,
    TableRow, TableCell, TableBody, Paper } from '@mui/material';
import Task from '../../../../models/Task';

const PricingTable: React.FC<{ task: Task }> = ({ task }) => {
    return (
        <TableContainer sx={{ pt: 1 }} component={Paper}>
            <Table size='small'>
                {!task.customCustomerPrice && (
                    <TableHead>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell align="right">Qty.</TableCell>
                            <TableCell align="right">Unit</TableCell>
                            <TableCell align="right">Sum</TableCell>
                        </TableRow>
                    </TableHead>
                )}
                <TableBody>
                    {!task.customCustomerPrice && (
                        <>
                            <TableRow>
                                <TableCell>Low Pages</TableCell>
                                <TableCell align="right">{task.lowPages}</TableCell>
                                <TableCell align="right">{`${task.lowPrice} €`}</TableCell>
                                <TableCell align="right">{`${(task.lowPrice && task.lowPages && task.lowPrice * task.lowPages)} €` ?? 'n/a'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>High Pages</TableCell>
                                <TableCell align="right">{task.highPages}</TableCell>
                                <TableCell align="right">{`${task.highPrice} €`}</TableCell>
                                <TableCell align="right">{`${(task.highPrice && task.highPages && task.highPrice * task.highPages)} €` ?? 'n/a'}</TableCell>
                            </TableRow>
                        </>
                    )}
                    <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell align="right">
                            <Typography variant='subtitle2' color='text.secondary'>Total</Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography variant='subtitle2'>{`${task.price} €`}</Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PricingTable;

