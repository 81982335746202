import { useState } from 'react';
import useHolidays from './useHolidays';
import { Card, Container, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import Actions from './customFields.tsx/Actions';
import HolidayMenu from './HolidayMenu';
import generateHolidayYears from '../../../utils/generateHolidayYears';

const Holidays: React.FC = () => {
    const [year, setYear] = useState(new Date().getFullYear());
    const { data: holidays } = useHolidays(year);
    const years = generateHolidayYears();

    return (
        <Container>
            <Card sx={{ p: 2 }}>
                <Stack>
                    <HolidayMenu year={year} years={years} setYear={setYear} />
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell align='right'></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {holidays?.map((holiday) => (
                                    <TableRow key={holiday.id}>
                                        <TableCell>{holiday.name}</TableCell>
                                        <TableCell>{moment(holiday.date).format('DD/MM/YYYY')}</TableCell>
                                        <TableCell>
                                            <Actions holiday={holiday} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </Card>
        </Container>
    );
};

export default Holidays;
