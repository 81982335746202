import React from 'react';
import { Stack } from '@mui/material';
import SelectRemoteOptions from '../../../components/Form/fields/SelectRemoteOptions';
import RadioField from '../../../components/Form/fields/RadioField';
import { surenessOptions } from '../../../enums/Sureness';
import TranslatorSelector from '../TranslatorSelector';
import TextField from '../../../components/Form/fields/TextField';
import CheckboxField from '../../../components/Form/fields/CheckboxField';
import { useField } from 'formik';

const TaskDetailEditor: React.FC<{ showTranslationOptions?: boolean, adminOptions?: boolean, disabled?: boolean }> = ({ showTranslationOptions, adminOptions, disabled }) => {
    const [field] = useField('include');
    const isIncludedTask = field.value;
    return (
        <Stack spacing={2}>
            <Stack direction={'row'} spacing={2}>
                <SelectRemoteOptions label={'From'} name={'sourceLanguageId'} queryKey={'languages'} disabled={disabled} />
                <SelectRemoteOptions label={'To'} name={'targetLanguageId'} queryKey={'languages'} disabled={disabled} />
            </Stack>
            <RadioField name={'sureness'} label={'How sure are you about the languages?'} options={surenessOptions} disabled={disabled}/>
            {showTranslationOptions && (
                <TranslatorSelector/>
            )}
            {showTranslationOptions || disabled ? (
                <Stack direction={'row'} spacing={2}>
                    <TextField label={'Low text pages'} name={'lowPages'} disabled={disabled}/>
                    <TextField label={'High text pages'} name={'highPages'} disabled={disabled}/>
                </Stack>
            ) : null}
            {adminOptions && (
                <Stack direction={'row'} spacing={2}>
                    {isIncludedTask && (
                        <CheckboxField name={'customCustomerPrice'} label={'Set customer price'} />
                    )}
                    <CheckboxField name={'customTranslatorPrice'} label={'Set translator price'} />
                </Stack>
            )}
        </Stack>
    );
};

export default TaskDetailEditor;
