import { Box, Card, Stack, Typography } from '@mui/material';
import React from 'react';
import SelectRemoteOptions from '../../../components/Form/fields/SelectRemoteOptions';
import RadioField from '../../../components/Form/fields/RadioField';
import { surenessOptions } from '../../../enums/Sureness';
import SwitcherFieldRevert from '../../../components/Form/fields/SwitcherFieldRevert';

const TranslationTask: React.FC = () => {
    return (
        <Card sx={{ p: 2 }}>
            <Stack spacing={2}>
                <Typography variant='subtitle2' gutterBottom>
                    Language Settings
                </Typography>
                <Box sx={{ m: 1 }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <SelectRemoteOptions label={'From'} name={'sourceLanguageId'} queryKey={'languages'} />
                        <SelectRemoteOptions label={'To'} name={'targetLanguageId'} queryKey={'languages'} />
                    </Stack>
                    <Box sx={{ mt: 3 }}>
                        <RadioField name={'sureness'} label={'How sure are you about the languages?'} options={surenessOptions} />
                    </Box>
                    <Box sx={{ m: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <SwitcherFieldRevert label={'Free task'} name={'include'} />
                    </Box>
                </Box>
            </Stack>
        </Card>
    );
};

export default TranslationTask;
