/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect } from 'react';
import { Card, Container, Grid, Stack } from '@mui/material';
import { useParams } from 'react-router';
import useTask from '../useTask';
import Form from '../../../components/Form';
import PostalAddressEditor from '../PostalAddressEditor';
import Page from '../../../components/Page';
import TaskDetailEditor from '../TaskDetailEditor';
import Task from '../../../models/Task';
import { TaskStatus } from '../../../enums/TaskStatus';
import useUpdateTask from '../useUpdateTask';
import TaskInfo from '../TaskInfo';
import FileByType from '../FilesByType';
import { FileType } from '../../../enums/FileType';
import Modifications from '../Modifications';
import CommentsSection from '../CommentsSection';
import TaskLoading from '../TaskLoading';
import { UserRole } from '../../../enums/UserRole';
import StatusHandlerButtons from '../../../utils/StatusHandler';
import { useQuery } from 'react-query';
import Customer from '../../../models/Customer';
import TextField from '../../../components/Form/fields/TextField';
import useSeenTask from '../useSeenTask';


const UserEditor: React.FC = () => {
    const { id: taskId } = useParams();
    const { data: task } = useTask(taskId);
    const { data: customer } = useQuery<Customer>(['customers/', task?.customerId], { enabled: !!task?.customerId });
    const { mutate: updateTask } = useUpdateTask(taskId);
    const { mutate: setSeen } = useSeenTask();

    useEffect(() => {
        setSeen({ id: taskId });
    }, [setSeen, taskId]);

    if (!task) {
        return <TaskLoading />;
    }

    return (
        <Page title="WeTranslate" >
            <Form<Task>
                validationSchema={undefined} onSubmit={updateTask} initialValues={task}>
                <Container maxWidth={'lg'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <Card sx={{ p: 2 }}>
                                    <TaskInfo task={task} isCustomer />
                                </Card>
                                <FileByType fileType={FileType.TRANSLATED} taskId={taskId} />
                                <FileByType fileType={FileType.ORIGINAL} taskId={taskId} />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <Card sx={{ p: 2, bgcolor: 'background.neutral' }}>
                                    <TaskDetailEditor disabled={
                                        task.status === TaskStatus.NEW_TASK || task.status === TaskStatus.INVALID_LANGUAGE ?
                                            false : true
                                    }/>
                                </Card>
                                {task.hardCopyRequired && (
                                    <Card>
                                        <PostalAddressEditor />
                                    </Card>
                                )}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <StatusHandlerButtons
                                    userId={task.userId}
                                    task={task!}
                                    role={UserRole.USER}
                                    size={'small'}
                                    shouldApprovePrice={customer?.mustApprovePrice ?? false}
                                />

                                <CommentsSection />

                                <Modifications taskId={taskId} />

                                <Card sx={{ p: 2 }}>
                                    <TextField name={'merchantId'} label={'Merchant ID'} />
                                </Card>

                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Form>
        </Page>
    );
};

export default UserEditor;
