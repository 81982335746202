import Task from '../../../models/Task';
import { TableBody, TableHead, TableRow, TableCell, Table, Typography, TableContainer } from '@mui/material';
import React from 'react';

const TaskDetails: React.FC<{ task: Task }> = ({ task }) => {
    return <TableContainer>
        <Table size={'medium'}>
            <TableHead>
                <TableRow>
                    <TableCell>Source language</TableCell>
                    <TableCell>Target language</TableCell>
                    <TableCell>Low page number</TableCell>
                    <TableCell>High page number</TableCell>
                    <TableCell>Low page price</TableCell>
                    <TableCell>High page price</TableCell>
                    <TableCell>Total price</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>
                        {task.sourceLanguage?.name}
                    </TableCell>
                    <TableCell>
                        {task.targetLanguage?.name}
                    </TableCell>
                    <TableCell>
                        {task.lowPages}
                    </TableCell>
                    <TableCell>
                        {task.highPages}
                    </TableCell>
                    <TableCell>{task.lowPrice ?? '-'} €</TableCell>
                    <TableCell>{task.highPrice ?? '-'} €</TableCell>
                    <TableCell>{task.price ?? '-'} €</TableCell>
                </TableRow>
            </TableBody>

            {task.currentBonusAmount && (
                <>
                    <TableHead>
                        <TableRow>
                            <TableCell>Bonus (%)</TableCell>
                            <TableCell>Bonus (€)</TableCell>
                            <TableCell>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{task.currentBonusPercent} %</TableCell>
                            <TableCell>{task.currentBonusAmount} €</TableCell>
                            <TableCell>
                                {(task.price ?? 0) + Number(task.currentBonusAmount)} €
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </>
            )}


            {task.currentPenaltyAmount && (
                <>
                    <TableHead>
                        <TableRow>
                            <TableCell>Penalty (%)</TableCell>
                            <TableCell>Penalty (€)</TableCell>
                            <TableCell>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{task.currentPenaltyPercent ?? '-'} %</TableCell>
                            <TableCell>{task.currentPenaltyAmount} €</TableCell>
                            <TableCell>
                                {(task.price ?? 0) - Number(task.currentPenaltyAmount)} €
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </>
            )}

            <TableHead>
                <TableRow>
                    <TableCell>Discount (%)</TableCell>
                    <TableCell>Discount (€)</TableCell>
                    <TableCell>Total</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>{task.currentDiscountPercent ?? '-'} %</TableCell>
                    <TableCell>{task.currentDiscountAmount ?? '-'} €</TableCell>
                    <TableCell>{task.price ?? '-'} €</TableCell>
                </TableRow>
                <TableRow><TableCell/></TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant='subtitle1' >
              Total price
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant='subtitle1'>
                            {task.price ?? '-'} €
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>;
};

export default TaskDetails;
