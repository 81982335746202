import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useField } from 'formik';
import { useQuery } from 'react-query';

const AutoComplete: React.FC<{ name: string; label: string; queryKey: string, queryOptions?: any }> = ({ name, queryOptions, label, queryKey }) => {
    const [field, meta] = useField(name);
    const { data: options } = useQuery<{id: string; name: string}[]>(queryKey, queryOptions);


    const hasError = Boolean(meta.touched && meta.error);
    const helperText = hasError && meta.error;


    const selectedOption = options?.find((opt) => opt.id === field.value);
    const value = selectedOption ? { label: selectedOption.name, id: selectedOption.id } : null;

    if (!options) {
        return null;
    }

    return <Autocomplete
        size="small"
        id="search-name"
        isOptionEqualToValue={(option, value) => option.id === value.id}
        fullWidth
        options={
            options.map((option) => {
                return {
                    label: option.name,
                    id: option.id,
                };
            }) ?? []
        }
        value={value}
        renderInput={(params) =>
            <TextField
                helperText={helperText}
                name={name}
                {...params}
                InputLabelProps={{ style: { fontSize: 14 } }} label={label} fullWidth />
        }
        onChange={(e, nextValue) => {
            field.onChange({
                target: {
                    name,
                    value: nextValue?.id,
                },
            });
        }}
    />;
};

export default AutoComplete;
