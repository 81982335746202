import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../utils/ApiClient';


function useDeleteTaskFile(id: string, taskId: string, onClose?: () => void) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: () => API.delete(`tasks/${taskId}/files/${id}`),
        onSuccess() {
            queryClient.invalidateQueries(`/tasks/${taskId}/files`);
            if (onClose) {
                onClose();
            }
        },
    });
}

export default useDeleteTaskFile;
