import React, { useMemo } from 'react';
import { Chip, Stack, Typography } from '@mui/material';
import TextField from '../../../components/Form/fields/TextField';
import { useField } from 'formik';

const TranslatorPricing: React.FC = () => {
    const [field] = useField('customTranslatorPrice');
    const [translatorPrice] = useField('translatorPrice');

    return useMemo(() => {
        const isCustomTranslatorPriceShown = field.value;
        return (
            <Stack alignItems={'center'} gap={2}>
                <Typography alignSelf={'flex-start'}
                    variant='subtitle2' color='text.secondary' gutterBottom>Translator pricing</Typography>
                {isCustomTranslatorPriceShown ?
                    <TextField label={'Translator price'} name={'translatorPrice'} /> :
                    <Chip label={`${translatorPrice.value ? translatorPrice.value + ' €' : 'not calculated yet'}`} color="info" />}
            </Stack>
        );
    }, [field.value, translatorPrice.value]);
};

export default TranslatorPricing;
