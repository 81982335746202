import { Grid } from '@mui/material';
import TextField from '../../../components/Form/fields/TextField';
import DatePicker from '../../../components/Form/fields/DatePicker';
import Button from '@mui/material/Button';
import FormDialog from '../../../components/FormDialog';
import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../../utils/ApiClient';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-eva/plus-fill';

const AddNewHoliday: React.FC = () => {
    const queryClient = useQueryClient();
    const { mutate: addNewHoliday } = useMutation({
        mutationFn: (holiday?: { name: string, date: string }) => API.post('holidays', holiday),
        onSuccess() {
            queryClient.invalidateQueries('holidays');
        },
    });

    return <>
        <FormDialog
            content={<Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField name={'name'} label={'Name'} />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker name={'date'} label={'Date'} />
                </Grid>
            </Grid>}
            onSubmit={(holiday) => addNewHoliday(holiday) }
            title={'Update holiday'}
            initialValues={{ name: '', date: '' }}
            submitLabel={'Save'}
            trigger={<Button startIcon={<Icon icon={add}/>} variant={'contained'}>new holiday</Button>} />
    </>;
};

export default AddNewHoliday;
