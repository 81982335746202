import React from 'react';
import useMe from '../../hooks/useMe';
import { UserRole } from '../../enums/UserRole';
import AdminEditor from './EditorByRole/Editor.admin';
import LinguistEditor from './EditorByRole/Editor.linguist';
import TranslatorEditor from './EditorByRole/Editor.translator';
import AgentEditor from './EditorByRole/Editor.agent';
import UserEditor from './EditorByRole/Editor.user';
import TaskLoading from './TaskLoading';


const TaskEditor = () => {
    const { data: me } = useMe();

    switch (me && me.role) {
    case UserRole.ADMIN:
        return <AdminEditor />;
    case UserRole.LINGUIST:
        return <LinguistEditor />;
    case UserRole.TRANSLATOR:
        return <TranslatorEditor />;
    case UserRole.AGENT:
        return <AgentEditor />;
    case UserRole.USER:
        return <UserEditor />;
    default:
        return <TaskLoading />;
    }
};

export default TaskEditor;
