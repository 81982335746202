import { useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { LanguageForm } from './Languages';
import Language from '../../../../models/Language';
import TextField from '../../../Form/fields/TextField';
import useDeleteLanguage from './useDeleteLanguage';
import { Button, IconButton, Stack } from '@mui/material';

const LanguageEditor: React.FC = () => {
    const { mutate: deleteLanguage } = useDeleteLanguage();
    const { setFieldValue, values } = useFormikContext<LanguageForm>();

    const onAddLanguage = () => {
        const language: Language = {
            id: uuidv4(),
            name: '',
            isNew: true,
        };

        setFieldValue('languages', [...values.languages, language]);
    };


    function removeLanguageByIndex(langIndex: number) {
        const targetLanguage = values.languages[langIndex];
        if (targetLanguage.isNew) {
            setFieldValue('languages', values.languages.filter((l, index) => index !== langIndex));
        } else {
            deleteLanguage({ id: targetLanguage.id });
        }
    }


    return (
        <Stack>
            {values.languages.map((language, index) => (
                <Stack direction={'row'} spacing={2} key={language.id} sx={{ my: 1 }}>
                    <TextField label={'Specified Language'} name={`languages[${index}].name`}
                        InputProps={{ endAdornment: (
                            <IconButton onClick={() => removeLanguageByIndex(index)}>
                                <Icon icon={trash2Outline} width={20} height={20} />
                            </IconButton>
                        ) }}/>
                </Stack>
            ))}
            <Stack direction='column' gap={2} alignItems="center">
                <Button color="inherit"
                    startIcon={<Icon icon={plusFill} width={20} height={20}/>}
                    onClick={onAddLanguage}
                >
                    Add language
                </Button>
                <Button type={'submit'} variant={'contained'}>Save</Button>
            </Stack>

        </Stack>
    );
};

export default LanguageEditor;
