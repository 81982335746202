import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import RoleGuard from '../components/guards/RoleGuard';
import { UserRole } from '../enums/UserRole';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import Page404 from '../pages/404';
import TaskEditor from '../pages/TaskEditor';
import TaskList from '../pages/TaskList';
// import Invoice from '../pages/admin/Invoice';
import AdminSettings from '../pages/admin/Settings';
import Login from '../pages/authentication/Login';
import NewPassword from '../pages/authentication/NewPassword';
import ResetPassword from '../pages/authentication/ResetPassword';
import CustomerSummary from '../pages/CustomerSummary';
import Maintenance from '../pages/Maintenance';
import Profile from '../pages/Profile';
import Reports from '../pages/Reports';
import ReportDetails from '../pages/Reports/ReportDetails/ReportDetails';
import NewTask from '../pages/NewTask';
import UserList from '../pages/admin/UserList';
import CustomerList from '../pages/admin/CustomerList';
import InitialPage from '../pages/InitialPage';
import Chat from '../pages/Chat';
import NewConversation from '../pages/Chat/NewConversation';
import Conversation from '../pages/Chat/Conversation';
import SummaryPage from '../pages/admin/SummaryPage';
import Dashboard from '../pages/admin/Dashboard';
import Customer from '../pages/Customer/Customer';
// ----------------------------------------------------------------------


export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: (
                <DashboardLayout />
            ),
            children: [
                { path: '', element:
                    <RoleGuard permissions={[
                        UserRole.ADMIN,
                        UserRole.AGENT,
                        UserRole.USER,
                        UserRole.LINGUIST,
                        UserRole.TRANSLATOR,
                    ]}>
                        <InitialPage />
                    </RoleGuard>,
                },
                { path: 'dashboard', element:
                        <RoleGuard permissions={[UserRole.ADMIN]}>
                            <Dashboard />
                        </RoleGuard>,
                },
                { path: 'profile/:id', element:
                    <RoleGuard permissions={[
                        UserRole.ADMIN,
                        UserRole.AGENT,
                        UserRole.USER,
                        UserRole.LINGUIST,
                        UserRole.TRANSLATOR,
                    ]}>
                        <Profile />
                    </RoleGuard>,
                },
                {
                    path: 'chat',
                    element:
                      <RoleGuard permissions={[
                          UserRole.ADMIN,
                          UserRole.AGENT,
                          UserRole.USER,
                          UserRole.LINGUIST,
                          UserRole.TRANSLATOR,
                      ]}>
                          <Chat />
                      </RoleGuard>,
                    children: [
                        {
                            path: 'new',
                            element: <NewConversation />,
                        },
                        {
                            path: ':userId',
                            element: <Conversation />,
                        },
                    ],
                },
                {
                    path: 'tasks',
                    children: [
                        { path: '', element: <TaskList /> },
                        { path: 'new', element:
                        <RoleGuard
                            permissions={[
                                UserRole.AGENT,
                                UserRole.USER,
                                UserRole.ADMIN,
                            ]}
                        >
                            <NewTask />
                        </RoleGuard> },
                        { path: ':id/edit', element:
                            <RoleGuard
                                permissions={[
                                    UserRole.ADMIN,
                                    UserRole.USER,
                                    UserRole.AGENT,
                                    UserRole.LINGUIST,
                                    UserRole.TRANSLATOR,
                                ]}
                            >
                                <TaskEditor />
                            </RoleGuard>,
                        },
                    ],
                },
                { path: 'settings', element:
                            <RoleGuard permissions={[UserRole.ADMIN]}>
                                <AdminSettings />
                            </RoleGuard>,
                },
                { path: 'summary', element:
                            <RoleGuard permissions={[UserRole.ADMIN]}>
                                <SummaryPage />
                            </RoleGuard>,
                },
                { path: 'customer-summary', element:
                            <RoleGuard permissions={[UserRole.AGENT]}>
                                <CustomerSummary />
                            </RoleGuard>,
                },
                {
                    path: 'reports',
                    children: [
                        { path: '', element:
                            <RoleGuard permissions={[UserRole.ADMIN, UserRole.TRANSLATOR]}>
                                <Reports />
                            </RoleGuard> },
                        { path: ':id', element:
                        <RoleGuard
                            permissions={[
                                UserRole.ADMIN,
                                UserRole.TRANSLATOR,
                            ]}
                        >
                            <ReportDetails />
                        </RoleGuard> },
                    ],
                },
                // { path: '/invoice', element:
                //             <RoleGuard permissions={[UserRole.ADMIN]}>
                //                 <Invoice />
                //             </RoleGuard>,
                // },
                // { path: '/customer-invoice', element:
                //             <RoleGuard permissions={[UserRole.AGENT]}>
                //                 <Invoice />
                //             </RoleGuard>,
                // },
                {
                    path: 'customers',
                    children: [
                        { path: '', element:
                        <RoleGuard
                            permissions={[
                                UserRole.ADMIN,
                                UserRole.AGENT,
                            ]}
                        >
                            <CustomerList />
                        </RoleGuard> },
                        { path: ':id', element:
                            <RoleGuard
                                permissions={[
                                    UserRole.ADMIN,
                                    UserRole.AGENT,
                                ]}
                            >
                                <Customer />
                            </RoleGuard>,
                        },
                    ],
                },
                { path: 'users', element:
                            <RoleGuard permissions={[
                                UserRole.ADMIN,
                                UserRole.LINGUIST,
                            ]}>
                                <UserList />
                            </RoleGuard>,
                },
            ],
        },
        {
            path: '*',
            element: <LogoOnlyLayout />,
            children: [
                { path: 'maintenance', element: <Maintenance /> },
                { path: '404', element: <Page404 /> },
                { path: '*', element: <Navigate to='/404' replace /> },
            ],
        },
        { path: '/login', element: <Login /> },
        { path: '/new-password', element: <NewPassword /> },
        { path: '/reset-password', element: <ResetPassword /> },
    ]);
}


