import React, { useMemo } from 'react';
import { useField } from 'formik';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';

type CheckboxFieldProps = {
    name: string
    label: string
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({ name, label }) => {
    const [field, meta] = useField(name);

    return useMemo(() => {
        const toggleCheckbox = () => {
            field.onChange({ target: { name, value: !field.value } });
        };

        return (
            <>
                <FormControlLabel
                    control={<Checkbox checked={!!field.value} onChange={toggleCheckbox} />}
                    label={label}
                />
                {meta.error && <Typography color={'error'}>{meta.error}</Typography>}
            </>
        );
    }, [field, label, name]);
};

export default CheckboxField;
