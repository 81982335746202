import React, { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { useField } from 'formik';
import PricingCalculator from './PricingCalculator';
import TextField from '../../../components/Form/fields/TextField';

const CustomerPricing: React.FC<{ taskId?: string }> = () => {
    const [field] = useField('customCustomerPrice');


    return useMemo(() => {
        const isPriceCalculatorHidden = field.value;
        return (
            <Stack alignItems={'center'} gap={2}>
                <Typography alignSelf={'flex-start'}
                    variant='subtitle2' color='text.secondary' gutterBottom>Customer pricing</Typography>
                {isPriceCalculatorHidden ?
                    <TextField label={'Customer price'} name={'price'} /> :
                    <PricingCalculator />}
            </Stack>
        );
    }, [field.value]);
};

export default CustomerPricing;
