import React from 'react';
import Task from '../../../models/Task';
import { Stack, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import Customer from '../../../models/Customer';
import Priority from '../../../models/Priority';
import useCustomers from '../../../hooks/useCustomers';

const TaskInfo: React.FC<{ task: Task, isCustomer?: boolean, isAdmin?: boolean }> = ({ task, isCustomer, isAdmin }) => {
    const { data: customers } = useCustomers();
    const { data: customer } = useQuery<Customer>(['customers/', task.customerId]);
    const { data: priority } = useQuery<Priority>(['priorities/', task.priorityId]);

    const companyName = customers?.find((c) => c.id === customer?.id)?.name;

    return <>
        <Stack direction={'row'} gap={2}>
            <Typography variant='subtitle2' color='text.secondary'>ID:</Typography>
            <Typography variant='subtitle2' >{task.taskId}</Typography>
            {!isCustomer && (
                <>
                    <Typography variant='subtitle2' color='text.secondary'>Company:</Typography>
                    <Typography variant='subtitle2' sx={{ maxWidth: 100, maxHeight: 20, overflow: 'hidden' }}>{companyName}</Typography>
                </>
            )}
        </Stack>
        <Stack direction={'row'} gap={2}>
            <Typography variant='subtitle2' color='text.secondary' >Priority:</Typography>
            <Typography variant='subtitle2'>
                {priority?.name} - {priority?.scannedHours} working hours
            </Typography>
        </Stack>
        <Stack direction={'row'} gap={2}>
            <Typography variant='subtitle2' color='text.secondary'>Type:</Typography>
            <Typography variant='subtitle2' sx={{ textTransform: 'capitalize' }}>{task?.style}</Typography>
            <Typography variant='subtitle2' color='text.secondary'>Hard Copy:</Typography>
            <Typography variant='subtitle2'>{task.hardCopyRequired ? 'Required' : 'Not Required'}</Typography>
        </Stack>
        {isCustomer && task.hardCopyRequired && (
            <Stack direction={'row'}>
                <Typography variant='subtitle2' color='text.secondary' sx={{ mr: 1 }}>Tracking Number:</Typography>
                <Typography variant='subtitle2'>{ task?.trackingNumber ?? 'n/a' }</Typography>
            </Stack>
        )}
        {!isCustomer && !isAdmin && (
            <Stack direction={'row'}>
                <Typography variant='subtitle2' color='text.secondary' sx={{ mr: 1 }}>Merchant ID:</Typography>
                <Typography variant='subtitle2'>{ task?.merchantId ?? 'n/a' }</Typography>
            </Stack>
        )}
        <Stack direction={'row'}>
            <Typography variant='subtitle2' color='text.secondary' sx={{ mr: 1 }}>Notes:</Typography>
            <Typography variant='subtitle2'>{ task?.note ?? 'n/a' }</Typography>
        </Stack>
    </>;
};

export default TaskInfo;
