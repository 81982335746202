import { TaskStatus } from '../enums/TaskStatus';

const env = process.env.REACT_APP_ENV;

interface ConfigInterface {
  env: 'production' | 'development' | 'dev_server' | 'staging' | 'demo';
  api: {
    socketProtocol: 'ws' | 'wss';
    protocol: 'http' | 'https';
    baseUrl: string;
    port: number;
  };
  statisticsStartDate: string;
  timeout: number;
  loginUrl: string;
  refreshTokenExpiry: number;
  paginationOptionsCount: number;
  uploadLimit: number;
  recaptchaSiteKey: string;
  regex: {
    lowerCase: RegExp;
    upperCase: RegExp;
    special: RegExp;
    number: RegExp;
  };
  allowedEndpoints: string[];
  errorStatuses: TaskStatus[];
  taskStatusesBySection: {
    newTask: TaskStatus[];
    actionNeeded: TaskStatus[];
    inProgress: TaskStatus[];
    finished: TaskStatus[];
  };
}

const baseConfig: ConfigInterface = {
    env: 'development',
    timeout: 20000,
    loginUrl: '/login',
    refreshTokenExpiry: 86400,
    paginationOptionsCount: 5,
    uploadLimit: 50971520,
    api: {
        socketProtocol: 'ws',
        protocol: 'http',
        baseUrl: 'localhost',
        port: 6060,
    },
    statisticsStartDate: '2012-01-01',
    recaptchaSiteKey: '6Ldo09IbAAAAAGXM-qBG-9av3A17bmSesGkvJbWb',
    regex: {
        lowerCase: /[a-z]/,
        upperCase: /[A-Z]/,
        // eslint-disable-next-line no-useless-escape
        special: /[@$!%*?&\+\-._:/\\=]/,
        number: /\d+/,
    },
    allowedEndpoints: [
        '/users/forgotpassword',
        '/users/newpassword',
        '/oauth/token',
    ],
    errorStatuses: [
        TaskStatus.INCORRECT_TRANSLATION,
        TaskStatus.INVALID_LANGUAGE,
        TaskStatus.INVALID_SOURCE_FILE,
        TaskStatus.PRICE_DECLINED,
    ],
    taskStatusesBySection: {
        newTask: [TaskStatus.NEW_TASK],
        actionNeeded: [
            TaskStatus.INVALID_SOURCE_FILE,
            TaskStatus.TRANSLATION_READY,
            TaskStatus.INCORRECT_TRANSLATION,
            TaskStatus.INVALID_LANGUAGE,
            TaskStatus.UNDER_TRANSLATION,
        ],
        inProgress: [
            TaskStatus.UNDER_TRANSLATION,
            TaskStatus.INVALID_SOURCE_FILE,
            TaskStatus.INVALID_LANGUAGE,
            TaskStatus.TRANSLATION_READY,
        ],
        finished: [TaskStatus.TRANSLATION_READY, TaskStatus.APPROVED],
    },
};

const prodConfig: ConfigInterface = {
    ...baseConfig,
    env: 'production',
    api: {
        socketProtocol: 'wss',
        protocol: 'https',
        baseUrl: 'api.wetranslate.international',
        port: 443,
    },
};

const devServerConfig: ConfigInterface = {
    ...baseConfig,
    env: 'dev_server',
    api: {
        socketProtocol: 'wss',
        protocol: 'https',
        baseUrl: 'api.wetranslate.digitalthinkers.hu',
        port: 443,
    },
};

const stagingConfig: ConfigInterface = {
    ...baseConfig,
    env: 'staging',
    api: {
        socketProtocol: 'wss',
        protocol: 'https',
        baseUrl: 'api.staging.wetranslate.international',
        port: 443,
    },
};

const demoServerConfig: ConfigInterface = {
    ...baseConfig,
    env: 'demo',
    api: {
        socketProtocol: 'wss',
        protocol: 'https',
        baseUrl: 'api.demo.wetranslate.international',
        port: 443,
    },
};

let config: ConfigInterface;

switch (env) {
case 'production':
    config = prodConfig;
    break;
case 'development':
    config = baseConfig;
    break;
case 'dev_server':
    config = devServerConfig;
    break;
case 'staging':
    config = stagingConfig;
    break;
case 'demo':
    config = demoServerConfig;
    break;
default:
    config = baseConfig;
}

export default config;
