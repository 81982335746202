import EntityBase from './EntityBase';
import Language from './Language';

export default class LanguagePair extends EntityBase {
    public sourceLanguageId?: string;
    public sourceLanguage?: Language;
    public targetLanguageId?: string;
    public targetLanguage?: Language;

    constructor(id: string) {
        super();
        this.id = id;
    }
}
