import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { API } from '../../../../utils/ApiClient';
import Language from '../../../../models/Language';


function useSaveLanguages() {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ languages }: {languages: Language[]}) => API.post('languages/bulkcreate', languages),
        onSuccess() {
            enqueueSnackbar('Languages saved', {
                variant: 'success',
            });
        },
    });
}


export default useSaveLanguages;
