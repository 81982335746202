import { Button, Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { PriceForm, TranslationOfferWithPrices } from './Prices';
import { useParams } from 'react-router-dom';
import useUserById from '../../../hooks/useUserById';
import { v4 as uuidv4 } from 'uuid';
import SelectRemoteOptions from '../../Form/fields/SelectRemoteOptions';
import React from 'react';
import TranslatorPrice from '../../../models/TranslatorPrice';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
import useDeleteTranslationOffer from './useDeleteTranslationOffer';
import PriceEditor from './PriceEditor';
import plusFill from '@iconify/icons-eva/plus-fill';

const PriceEditors: React.FC = () => {
    const { mutate: deleteOffer } = useDeleteTranslationOffer();
    const { id } = useParams();
    const { data: user } = useUserById(id);
    const { setFieldValue, values } = useFormikContext<PriceForm>();

    const onAddPrice = () => {
        const offer: TranslationOfferWithPrices = {
            id: uuidv4(),
            user,
            userId: user?.id,
            translatorPrices: [],
            prices: [],
            sourceLanguageId: '',
            targetLanguageId: '',
            formType: 'new',
        };

        setFieldValue('offers', [...values.offers, offer]);
    };

    const addHoursToOffer = (offerIndex: number, translationOfferId?: string) => {
        const newPrice: TranslatorPrice = {
            id: uuidv4(),
            freeHighPrice: 0,
            freeLowPrice: 0,
            hours: 0,
            swornHighPrice: 0,
            swornLowPrice: 0,
            translationOfferId,
            user,
            formType: 'new',
        };

        setFieldValue('offers', values.offers.map((offer, index) => index === offerIndex ? {
            ...offer,
            translatorPrices: [...offer.translatorPrices, newPrice],
        } : offer));
    };


    function removeOfferByIndex(offerIndex: number) {
        const offerToDelete = values.offers[offerIndex];
        if (offerToDelete.formType === 'new') {
            setFieldValue('offers', values.offers.filter((offer, index) => index !== offerIndex));
        } else {
            deleteOffer({ id: offerToDelete.id });
        }
    }


    return <>
        <Grid item xs={12}>
            <Button
                endIcon={<Icon icon={plusFill}/>}
                variant={'outlined'} onClick={onAddPrice}>Add price</Button>
        </Grid>
        {values.offers.map((offer, index) => (
            <Grid item xs={12} key={offer.id}>
                <Card sx={{ p: 2 }}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
                        <Typography variant={'h6'}>{index + 1}. Price offer</Typography>
                        <IconButton onClick={() => removeOfferByIndex(index)}>
                            <Icon icon={trash2Outline}/>
                        </IconButton>
                    </Stack>
                    <Stack direction={'row'} spacing={2}>
                        <SelectRemoteOptions label={'From'} name={`offers[${index}].sourceLanguageId`} queryKey={'languages'}/>
                        <SelectRemoteOptions label={'To'} name={`offers[${index}].targetLanguageId`} queryKey={'languages'}/>
                    </Stack>
                    <PriceEditor offer={offer} index={index}/>
                    <Button sx={{ mt: 2 }}
                        endIcon={<Icon icon={plusFill}/>}
                        onClick={() => addHoursToOffer(index, offer.id)}>Add hours</Button>
                </Card>
            </Grid>
        ))}
    </>;
};

export default PriceEditors;
