/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import closeFill from '@iconify/icons-eva/close-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
import trash2fill from '@iconify/icons-eva/trash-2-fill';
import { Icon } from '@iconify/react';
import {
    Box, Button, Card, CardContent, Container, FormControl, Grid, InputLabel,
    MenuItem, Select, Stack, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Language from '../../../models/Language';
import LanguagePair from '../../../models/LanguagePair';
import useAPI from '../../../utils/ApiClient';
import { MIconButton } from '../../@material-extend';

// ----------------------------------------------------------------------

export default function LanguagePairs(): JSX.Element {
    const [languagePairs, setLanguagePairs] = useState<LanguagePair[]>();
    const [languages, setLanguages] = useState<Language[]>();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [, getLanguages] = useAPI(`/languages`, { manual: true });
    const [, getLanguagePairs] = useAPI(`/languagepairs`, { manual: true });
    const [, deleteLanguagePair] = useAPI({ method: 'DELETE' }, { manual: true });
    const [, postLanguagePairs] = useAPI({ url: '/languagepairs/bulkcreate', method: 'POST' }, { manual: true });

    const handleSelectedLanguage = (langValueId: string, type: 'source' | 'target', langId: string) => {
        const languagePair = languagePairs?.find((pair) => pair.id === langId);
        if (languagePair) {
            if (type === 'source') {
                languagePair.sourceLanguageId = langValueId;
            } else {
                languagePair.targetLanguageId = langValueId;
            }
        }
        setLanguagePairs([...(languagePairs ?? [])]);
    };

    const mapLanguages = (langs: Language[], disabled?: boolean): JSX.Element[] => {
        return langs.map((l) => (
            <MenuItem key={l.id} value={l.id} disabled={disabled ?? false}>
                {l.name}
            </MenuItem>
        ));
    };

    const handleDelete = (id: string) => {
        deleteLanguagePair({ url: `/languagepairs/${id}` })
            .then((res) => {
                enqueueSnackbar(res.status === 204 ? 'Language pair deleted' : 'Error occured', {
                    variant: res.status === 204 ? 'success' : 'error',
                    action: (key) => (
                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    ),
                });
            });

        setLanguagePairs([...(languagePairs?.filter((pair) => pair.id !== id) ?? [])]);
    };

    const handleNewLanguagePair = () => {
        const pair = new LanguagePair(uuidv4());
        setLanguagePairs([...(languagePairs ?? [])].concat(pair));
    };

    const handleSaveLanguagePairs = () => {
        postLanguagePairs({ data: languagePairs ?? [] }).then((res) => {
            enqueueSnackbar(res.status === 200 ? 'Language pairs created' : 'Failed to create language pairs', {
                variant: res.status === 200 ? 'success' : 'error',
                action: (key) => (
                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </MIconButton>
                ),
            });
        });
    };

    useEffect(() => {
        getLanguages()
            .then((res) => setLanguages(res.data))
            .catch((err) => {
                console.error(err);
            });
        getLanguagePairs()
            .then((res) => setLanguagePairs(res.data))
            .catch((err) => console.error(err));
    }, [getLanguagePairs, getLanguages]);

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    <Card sx={{ mb: 3, p: 2, textAlign: 'center' }}>
                        <Typography>
                        Newly created tasks which have these language pairs will not be sent to the Linguist.
                        </Typography>
                    </Card>
                    {languagePairs && languagePairs.map((languagePair) => (
                        <Card sx={{ mb: 3, p: 2 }} key={languagePair.id}>
                            <CardContent>

                                <Stack spacing={3}>
                                    <FormControl variant="outlined">
                                        <InputLabel id='select-origin-language-label'>
                                        Choose the source language
                                        </InputLabel>
                                        <Select
                                            id='select-origin-language'
                                            labelId='select-origin-language-label'
                                            label='Choose the source language'
                                            value={languagePair.sourceLanguageId}
                                            onChange={(e) => handleSelectedLanguage(
                                                e.target.value,
                                                'source',
                                                languagePair.id ?? '',
                                            )
                                            }
                                        >
                                            {(languagePair.sourceLanguageId && languages) ? (
                                                mapLanguages(languages.filter(
                                                    (lang) => lang.id === languagePair.sourceLanguageId) ?? [], true)
                                            ) : (languagePair.targetLanguageId && languages) ?
                                                (mapLanguages(
                                                    languages.filter((l) => l.id !== languagePair.targetLanguageId &&
                                                    !languagePairs.filter((lp) =>
                                                        lp.targetLanguageId === languagePair.targetLanguageId)
                                                        .map((pair) => pair.sourceLanguageId)
                                                        .includes(l.id)))
                                                ) : (mapLanguages(languages ?? []))
                                            }
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="outlined">
                                        <InputLabel id='select-target-language-label'>
                                        Choose the target language
                                        </InputLabel>
                                        <Select
                                            id='select-target-language'
                                            labelId='select-target-language-label'
                                            label='Choose the target language'
                                            value={languagePair.targetLanguageId}
                                            onChange={(e) => handleSelectedLanguage(
                                                e.target.value,
                                                'target',
                                                languagePair.id ?? '',
                                            )}
                                        >
                                            {(languagePair.targetLanguageId && languages) ? (
                                                mapLanguages(languages.filter(
                                                    (lang) => lang.id === languagePair.targetLanguageId) ?? [], true)
                                            ) : (languagePair.sourceLanguageId && languages) ?
                                                (mapLanguages(
                                                    languages.filter((l) => l.id !== languagePair.sourceLanguageId &&
                                                    !languagePairs.filter((lp) =>
                                                        lp.sourceLanguageId === languagePair.sourceLanguageId)
                                                        .map((pair) => pair.targetLanguageId)
                                                        .includes(l.id)))) :
                                                (mapLanguages(languages ?? []))
                                            }
                                        </Select>
                                    </FormControl>

                                </Stack>
                                <Box sx={{ textAlign: 'center', color: 'red' }}>
                                    <Button color="inherit"
                                        startIcon={<Icon icon={trash2fill} width={20} height={20} />}
                                        sx={{ mt: 3 }}
                                        onClick={() => handleDelete(languagePair.id ?? '')}
                                    >
                                    Delete
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    ))}

                    <Box sx={{ textAlign: 'center' }}>
                        <Button
                            startIcon={<Icon icon={plusFill} width={20} height={20} />}
                            sx={{ mt: 1, color: 'theme.palette.text.primary' }}
                            onClick={() => handleNewLanguagePair()}
                        >
                        Add new Language pair
                        </Button>
                    </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Stack spacing={3}>
                        <LoadingButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            size="large"
                            onClick={() => handleSaveLanguagePairs()}
                        >
                        Save
                        </LoadingButton>
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    );
}
