import CommentType from '../../../models/Comment';
import { Icon } from '@iconify/react';
import moment from 'moment';
import fileIcon from '@iconify/icons-eva/file-text-outline';
import { Box, Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { PersonPinCircleTwoTone } from '@mui/icons-material';
import axios, { AxiosResponse } from 'axios';
import useAPI from '../../../utils/ApiClient';

const Comment: React.FC<{ comment: CommentType }> = ({ comment }) => {
  const [, downloadFile] = useAPI({ method: 'GET' }, { manual: true });
  const date = moment(comment.created).format('MM/DD/YY HH:mm');

  const fullName = comment.user ? `${comment.user?.firstname} ${comment.user?.lastname}` : 'Anonymous';

  const handleDownload = async () => {
    const url = `/comments/${comment.id}/download`;
    const data = (await downloadFile({ url })).data;
    const file = await axios.get(data, {
      responseType: 'arraybuffer',
    });

    triggerFileDownload({ response: file, fileName: data.filename });
  };

  const triggerFileDownload = ({
    response,
    contentType,
    fileName,
  }: {
    response: AxiosResponse;
    contentType?: string;
    fileName?: string;
  }) => {
    const disposition = response.headers['content-disposition'];
    let name: string;
    if (disposition) {
      name = disposition.substring(disposition.indexOf('"') + 1, disposition.lastIndexOf('"'));
    } else if (fileName) {
      name = fileName;
    } else {
      name = 'file';
    }

    const type = contentType ? contentType : response.headers['content-type'];

    const blob = new Blob([response.data], { type });
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = name;
    document.body.appendChild(a);
    a.click();
  };


  return (
    <Stack gap={0.5}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant={'subtitle2'} color={'primary'} display={'flex'} alignItems={'center'}><PersonPinCircleTwoTone sx={{ mr: 0.5 }} />{fullName} </Typography>
        {comment.user && <Chip variant={'outlined'} color={'primary'} size={'small'} label={comment.user?.role} />}
      </Stack>
      {comment.text ? (
        <Typography variant={'body1'} bgcolor={'background.neutral'} borderRadius={1} py={1} px={2} ml={3.5}>
          {comment.text}
        </Typography>
      ) : (
        <Box bgcolor={'background.neutral'} borderRadius={1} py={1} px={2} ml={3.5}>
          <Tooltip title="Download File">
            <IconButton onClick={handleDownload}>
              <Icon color="inherit" icon={fileIcon} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <Typography align={'right'} variant={'caption'} color={'text.secondary'}>{date}</Typography>
    </Stack>
  );
};

export default Comment;
